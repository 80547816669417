<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card card-layout off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2">
            <v-card-text class="card-state pa-1" :class="g_cardState(item.active)">

                <!--HEADER-->
                <slot name="header" v-if="!no_header">

                    <!--NEW HEADER-->
                    <v-row no-gutters justify="center">
                        <v-col cols="12" md="6">
                            <span>{{item.registration}}</span> • <span>{{item.id}}</span>
                        </v-col>
                        <v-col cols="12" md="6">
                            <span class="tag-status gold white--text px-3 rounded" :class="item.state">{{item.position}}</span>
                        </v-col>
                        <v-col cols="12">
                            <v-divider class="gold lighten-1"></v-divider>
                        </v-col>
                    </v-row>

                    <v-row no-gutters justify="center" v-if="true">
                        <v-col cols="12" class="pb-0" md="6">
                            <span class="font-weight-bold">
                                {{item.date}} • <span class="gold--text">{{item.id}}</span>
                            </span>
                        </v-col>
                        <v-col cols="12" md="6" class="font-weight-medium">
                            <span class="gold lighten-1 white--text px-3 rounded">{{item.product}}</span>
                            <span class="tag-status white--text rounded px-3 ml-1" :class="item.state"> {{item.status}}</span>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" no-gutters>
                                <v-icon small class="gold--text pr-1">mdi-account-circle</v-icon>
                                <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">{{ item.customer }}</span>
                            </v-row>

                            <v-divider class="gold lighten-1"></v-divider>
                        </v-col>
                    </v-row>

                </slot>

                <!--CONTENT-->
                <slot name="content" v-if="!no_content">
                    <v-row justify="center" align="center" no-gutters>
                        <v-row align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                            <v-col v-bind="layout" v-for="v in keys" :key="v.value">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" class="caption font-weight-bold">{{ g_i18_keys(v.value) }}</v-col>
                                    <v-col cols="12" class="gold--text">{{ g_isDefined(item[v.value]) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-row>
                </slot>

                <!--FOOTER-->
                <slot name="footer" v-if="!no_footer">
                    <v-row justify="center" align="center" no-gutters>
                        <v-row no-gutters align="center" class="pa-0 ma-0 rounded">
                            <v-icon size="20" class="px-1">mdi-account-tie</v-icon>
                            <span class="caption">{{ item.seller }}</span>
                        </v-row>
                    </v-row>
                </slot>

            </v-card-text>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "CardLayout",
    props: {
        item: Object,
        keys: undefined,
        state: undefined,
        no_header: Boolean,
        no_content: Boolean,
        no_footer: Boolean
    },
    data: () => ({
        card: { cols: 6, xs: 6, sm: 3, md: 0, xl: 0, align: 'center', class: 'my-2 full-cols'}
    }),
    computed: {
        layout() {
            return this.card;
        }
    },
    mounted() {

    }
}
</script>